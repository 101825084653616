export const landlordManagerList = [
  {
    id: 20111,
    no: '1.',
    location: '17, Street 7, GRA, Osogbo, Osun State.',
    email: 'peaceadekola2@gmail.com',
    phoneNo: '08146573112',
    name: 'Mr. Susan',
  },
  {
    id: 20112,
    no: '2.',
    location: '20, Street 7, GRA, Osogbo, Osun State.',
    email: 'hi.kellyui@gmail.com',
    phoneNo: '09054441156',
    name: 'Mr. Susan',
  },
]
