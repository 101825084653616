export const landlordNotififcations = [
  {
    id: 201,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
  {
    id: 202,
    date: 'dd/mm/yyy',
    time: '2:00pm',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Not opened',
    style: { color: 'white', backgroundColor: 'black' },
  },
  {
    id: 203,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
  {
    id: 204,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
]
