export const tenantNotififcations = [
  {
    id: 210,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Read',
    style: { color: 'black'},
  },
]
