export const landlordTenantList = [
  {
    id: 20011,
    no: '1.',
    location: '17, Street 7, GRA, Osogbo.',
    name: 'Mr. Susan',
    property: 'Self con',
    unit: 'Grace',
    amount: '#500,000.00',
    renewStatus: 'Renewing',
    reminder: 'Send Reminder',
    paymentStatus: 'Not Completed',
    style: { color: 'white', backgroundColor: 'red' },
  },
  {
    id: 20012,
    no: '2.',
    location: '17, Street 7, GRA, Osogbo.',
    name: 'Mr. Susan',
    property: 'Bungalow',
    unit: 'Peace',
    amount: '#3,000,000.00',
    renewStatus: 'Terminating',
    reminder: 'Send Reminder',
    paymentStatus: 'Completed',
    style: { color: 'white', backgroundColor: 'green' },
  },
  {
    id: 20013,
    no: '3.',
    location: '17, Street 7, GRA, Osogbo.',
    name: 'Mr. Susan',
    property: 'Bungalow',
    unit: 'Peace',
    amount: '#3,000,000.00',
    renewStatus: 'Requested renegotiation ',
    reminder: 'Send Reminder',
    paymentStatus: 'Completed',
    style: { color: 'white', backgroundColor: 'green' },
  },
  {
    id: 20014,
    no: '4.',
    location: '17, Street 7, GRA, Osogbo.',
    name: 'Mr. Susan',
    property: 'Bungalow',
    unit: 'Peace',
    amount: '#3,000,000.00',
    renewStatus: 'Renegotiating',
    reminder: 'Send Reminder',
    paymentStatus: 'Completed',
    style: { color: 'white', backgroundColor: 'green' },
  },
]
 