export const managerLandlordList = [
  {
    id: 21111,
    no: '1.',
    location: '17, Street 7, GRA, Osogbo, Osun State.',
    email: 'hi.kellyui@gmail.com',
    phoneNo: '09054441156',
    name: 'Mr. Susan',
  },
  {
    id: 21112,
    no: '2.',
    location: '20, Street 7, GRA, Osogbo, Osun State.',
    email: 'hi.kellyui@gmail.com',
    phoneNo: '09054441156',
    name: 'Mr. Susan',
  },
]
