export const managerNotififcations = [
  {
    id: 211,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
  {
    id: 212,
    date: 'dd/mm/yyy',
    time: '2:00pm',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Not opened',
    style: { color: 'white', backgroundColor: 'black' },
  },
  {
    id: 213,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
  {
    id: 214,
    date: 'dd/mm/yyy',
    time: '10:15am',
    desc: 'This is to notify you that your Mr. Sina’s rent will be due on the 20th of Februa............ ',
    status: 'Opened',
    style: { color: 'black', backgroundColor: 'white' },
  },
]
